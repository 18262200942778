import { post, App_ID } from "./publicHeader";
class Order {
  // 点击立即购买展示页面
  async goBuyNow(goods_id, products_id, num) {
    try {
      const res = await post("/shop/user.order/buynow", {
        app_id: App_ID,
        goods_id,
        products_id,
        num
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 立即购买提交订单
  async goBuyOrder(source, order_type, receipt_type, address_id, store_id, lading_name, lading_mobile, goods_id, products_id, buy_num, message) {
    try {
      const res = await post("/shop/user.order/create", {
        app_id: App_ID,
        source,
        order_type,
        receipt_type,
        address_id,
        store_id,
        lading_name,
        lading_mobile,
        goods_id,
        products_id,
        buy_num,
        message
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 购物车创建订单
  async carCreate(source, payment_code, address_id, list) {
    try {
      const res = await post("/shop/user.order/cartCreateOrder", {
        app_id: App_ID,
        source,
        payment_code,
        address_id,
        list
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 支付订单
  async goPayOrder(ordersn, payment_code) {
    try {
      const res = await post("/shop/user.order/pay", {
        app_id: App_ID,
        ordersn,
        payment_code
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 获取订单列表
  async getOrderAll(limit = 10, page = 1, status) {
    const res = await post("/shop/user.order/getList", {
      app_id: App_ID,
      limit,
      page,
      status
    });
    return res;
  }
  // 获取订单详情
  async getOrderDetail(ordersn) {
    try {
      const res = await post("/shop/user.order/details", {
        app_id: App_ID,
        ordersn: ordersn
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 取消未支付订单
  async outOderPay(ordersn, cancel_reason, cancel_desc) {
    try {
      const res = await post("/shop/user.order/cancel", {
        app_id: App_ID,
        ordersn: ordersn,
        cancel_reason,
        cancel_desc
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 删除已取消的订单
  async delOutOrder(ordersn) {
    try {
      const res = await post("/shop/user.order/del", {
        app_id: App_ID,
        ordersn: ordersn
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 完全删除订单
  async delLongOrder( ordersn) {
    try {
      const res = await post("/shop/user.order/delComplete", {
        ordersn: ordersn
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 确认收货
  async makeGoods(ordersn) {
    try {
      const res = await post("/shop/user.order/confirm", {
        app_id: App_ID,
        ordersn: ordersn
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 发布评价
  async issueEvaluate(ordersn, items) {
    try {
      const res = await post("/shop/user.order/comment", {
        app_id: App_ID,
        ordersn: ordersn,
        items: items
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 上传图片
  async upImg(image, file_group) {
    try {
      const res = await post("/shop/user.upload/uploads", {
        app_id: App_ID,
        image: image,
        file_group
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 油卡优惠
  async DisPayAvailable(payment_code, account_id, room_id, goods_id, money, recharge_id, idx) {
    try {
      const res = await post("/offlinestore/user.pay/account_available", {
        payment_code,
        account_id,
        room_id,
        goods_id,
        money,
        recharge_id,
        key: idx,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 支付富有充值订单
  async createPayment (pay_token, paysn) {
    return await post( 'client/order/create_payment', {
      pay_token,
      paysn
    })
  }
}

export default new Order();
