<template>
  <div class="evaluate">
    <nav-bar title="发表评价" left-text right-text="发布" left-arrow @click-left="onClickLeft" @click-right="upImg" />
    <div v-for="(item, index) in shopList.goods" :key="index">
      <div class="describe">
        <div class="img">
          <img :src="item.image_url" alt="" />
        </div>
        <div class="details">
          <div class="title font_hidden">
            <span>
              {{ item.name }}
            </span>
          </div>
          <div class="text font_hidden">
            <span>
              {{ item.amount }}
            </span>
          </div>
        </div>
      </div>
      <div class="star_level">
        <span style="margin-right:10px">商品评价</span>
        <rate v-model="commentdetils[item.id].score" />
      </div>
      <div class="content">
        <field v-model="commentdetils[item.id].content" placeholder="宝贝满足你的期待吗？说说他的优点和美中不足" type="textarea" rows="4" />
        <div class="upImg">
          <Uploader v-model="newFileLists[index]" multiple class="Uploader" :after-read="afterRead">
            <img src="@static/home/photo.png" alt=""/>
          </Uploader>
        </div>
      </div>
      <div class="padd"></div>
    </div>
    <div class="star_level"><span style="margin-right:10px">店铺评价</span></div>
    <div class="star_level"><span style="margin-right:10px">服务态度</span><rate v-model="serveScore" /></div>
    <div class="star_level"><span style="margin-right:10px">物流服务</span><rate v-model="logistics" /></div>
    <div class="star_level"><span style="margin-right:10px">商品描述</span><rate v-model="describe" /></div>
  </div>
</template>

<script>
import Order from "@/api/order";
import { Toast, NavBar, Field, Uploader, Rate } from "vant";
// import { constants } from 'crypto';
export default {
  components: {
    NavBar,
    Field,
    Rate,
    Uploader
  },
  data() {
    return {
      value: 0,
      textarea: "",
      fileList: [],
      shopList: [], //商品列表
      succeedIdList: [], //上传完图片返回的id数组
      ordersn: this.$route.query.ordersn,
      commentdetils: {}, //评论内容绑定项
      objLength: "",//对象长度
      newFileLists: [], // 每一个评价的图片列表
      pushDynamic: {
        score: 0, // 商品评价
        content: "",
        images: ""
      },
      serveScore: 0, // 服务评价
      logistics: 0, // 物流评价
      describe: 0, // 商品描述
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //
    upImg() {
      this.objLength = Object.keys(this.commentdetils).length;
      let i = 0;
      if(this.newFileLists[0].length == 0) {
        this.upData();
      } else {
        while (i < this.newFileLists.length) {
          this.upimgPort(this.newFileLists[i], i);
          i++;
        }
      }
    },
    // 上传评论
    async upData() {
      try {
        const res = await Order.issueEvaluate(this.ordersn, this.commentdetils);
        Toast (res.msg)
        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      }
    },
    // 上传图片到服务器
    async upimgPort(filedata, idx) {
      if(filedata.length == 0) {
          console.log(idx);
      } else {
        for(let i in filedata) {
          try {
            const res = await Order.upImg(filedata[i].file, 4);
            this.succeedIdList.push(res.data.id);
            console.log(this.succeedIdList.join());
            if (this.succeedIdList.length == filedata.length) {//是否循环完当前数组
              this.commentdetils[this.shopList.goods[idx].id].images = this.succeedIdList.join();
              this.succeedIdList = [];
              if (idx + 1 == this.objLength) {
                this.upData();
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
      
    },
    async orderDetail() {
      try {
        const res = await Order.getOrderDetail(this.ordersn);
        console.log(res);
        this.shopList = res.data;
        for (let i in res.data.goods) {
          this.commentdetils[res.data.goods[i].id] = this.pushDynamic;
          this.newFileLists.push(new Array());
          // 二维数组
          console.log(this.commentdetils);
          console.log(this.newFileLists);
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 图片上传成功
    afterRead() {
      console.log(this.newFileLists);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.orderDetail();
    });
  }
};
</script>

<style lang="scss" scoped>
.evaluate {
  .describe {
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #b5b5b52e;
    .img {
      width: 50px;
      height: 50px;
      background: #51f55f69;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .details {
      width: 280px;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .text {
        font-size: 12px;
        color: #b5b5b5;
      }
      .font_hidden {
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .content {
    .text {
      min-height: 100px;
    }
    .upImg {
      padding: 0 11px;
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .padd {
    height: 8px;
    background: #b5b5b52e;
    width: 100%;
  }
  .star_level {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
  }
}
</style>

<style lang="scss">
.evaluate {
  .van-nav-bar .van-icon {
    color: #000;
  }
  .van-nav-bar__text {
    color: #ff8000;
  }
  .van-nav-bar {
    box-shadow: 0px 1px 4px #d0d0d0;
  }
  .van-cell:not(:last-child)::after {
    border: 0;
  }
}
</style>
